@import "~antd/dist/antd.less";

@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-upload-draggable-list-item {
  border-top: 2px dashed rgba(0, 0, 0, 0);
  border-bottom: 2px dashed rgba(0, 0, 0, 0);
}

.ant-upload-draggable-list-item.drop-over-downward {
  border-bottom-color: #1890ff;
}

.ant-upload-draggable-list-item.drop-over-upward {
  border-top-color: #1890ff;
}

.ant-input[disabled].readOnly {
  border: 0 !important;
  background: white !important;
  color: #000 !important;
  cursor: auto !important;
}

.readOnly .ant-upload-disabled.ant-upload-select {
  display: none;
}

.ql-container {
  max-height: 750px;
  overflow: auto;
}

// -----------------------------

.message {
  &-create,
  &-edit,
  &-index {
    .ant-btn.ant-btn-primary {
      display: none;
    }
  }
}

.top-page-search{
  width:calc( 100%  - 400px);
  position: absolute;
  top: 20px;
}

